var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mergeLoading,
          expression: "mergeLoading",
        },
      ],
      attrs: {
        "custom-class": "customer-merge-dialog",
        title: _vm.dialogTitle,
        "show-close": false,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "customer-merge-tip" },
        [
          _vm.isMerge
            ? [
                _c("p", [
                  _vm._v(
                    "合并后，该客户所有信息将合并为你选中的企业信息。且所属的所有职位将归属于被选中的企业。"
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "举例：将客户“阿里”，合并到“阿里巴巴”，那么合并后，将只存在“阿里巴巴”，且原“阿里”的职位将全部归属于“阿里巴巴”的职位"
                  ),
                ]),
              ]
            : [_c("p", [_vm._v("该客户所有信息将成为你选中企业的子部门。")])],
        ],
        2
      ),
      _c("customer-select", {
        ref: "customerSelect",
        staticClass: "customer-merge-select",
        attrs: { placeholder: "搜索客户简称/全称" },
        on: { customerSelected: _vm.handleCustomerSelected },
      }),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: { click: _vm.handleCustomerMerge },
            },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.handleCloseDialog } }, [
            _vm._v("取消"),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }