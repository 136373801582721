<template>
    <el-dialog
        custom-class="customer-merge-dialog"
        :title="dialogTitle"
        :show-close="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        v-loading="mergeLoading">
        <div class="customer-merge-tip">
            <template v-if="isMerge">
                <p>合并后，该客户所有信息将合并为你选中的企业信息。且所属的所有职位将归属于被选中的企业。</p>
                <p>举例：将客户“阿里”，合并到“阿里巴巴”，那么合并后，将只存在“阿里巴巴”，且原“阿里”的职位将全部归属于“阿里巴巴”的职位</p>
            </template>
            <template v-else>
                <p>该客户所有信息将成为你选中企业的子部门。</p>
            </template>
        </div>
        <customer-select
            ref="customerSelect"
            class="customer-merge-select"
            placeholder="搜索客户简称/全称"
            @customerSelected="handleCustomerSelected"
        ></customer-select>
        <div class="dialog-footer" slot="footer">
            <el-button type="primary" @click="handleCustomerMerge">确定</el-button>
            <el-button @click="handleCloseDialog">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import CustomerSelect from '#/component/common/customer-select.vue';
import customerListService from '#/js/service/customerListService.js';

export default {
    components: {
        CustomerSelect
    },
    props: {
    },
    computed: {
        dialogTitle() {
            return this.isMerge ? '合并到另一客户' : '成为另一客户的子部门'
        }
    },
    data() {
        return {
            customerId: "", 
            customerName: "",
            dialogVisible: false,
            mergeLoading: false,
            isMerge: true,
            mergeCustomerId: "",
            mergeCustomerName: "",
        }
    },
    mounted() {},
    methods: {
        show(isMerge, customer) {
            this.isMerge = isMerge;
            this.customerId = customer.customerId;
            this.customerName = customer.customerName;
            this.dialogVisible = true;
        },
        handleCustomerSelected(customer) {
            this.mergeCustomerId = customer.customerId;
            this.mergeCustomerName = customer.customerName;
        },
        handleCustomerMerge() {
            if(!this.mergeCustomerId) {
                shortTips('请选择存在的客户');
                return false;
            } else {
                let tip = "",
                    // url = "",
                    shortTipsText = "",
                    json = {
                        from: this.customerId,
                        to: this.mergeCustomerId
                    }
                // if(this.isMerge) {
                //     tip = `确定将现有客户"${this.customerName}"合并到 ${this.mergeCustomerName}"，该操作不可恢复！`;
                //     url = "/MyCompany/SubCustomer/MergeToCustomer";
                //     shortTipsText = "合并成功";
                // } else {
                //     tip = `确定将现有客户"${this.customerName}"整合成为"${this.mergeCustomerName}"的子部门，该操作不可恢复`;
                //     url = "/MyCompany/SubCustomer/ChangeToSubDepartment";
                //     shortTipsText = "成功添加子部门";
                // }
                tip = `确定将现有客户"${this.customerName}"合并到 ${this.mergeCustomerName}"，该操作不可恢复！`;
                shortTipsText = "合并成功";
                this.$confirm(tip, '提示', {
                    type: 'warning',
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false
                }).then(res => {
                    this.mergeLoading = true;
                    // _request({
                    //     url: url,
                    //     method: "POST",
                    //     headers: {
                    //         'Content-Type': 'application/x-www-form-urlencoded'
                    //     },
                    //     data: json
                    // })
                    customerListService.mergeToCustomer(json)
                    .then(res => {
                        this.mergeLoading = false;
                        shortTips(shortTipsText);
                        this.handleCloseDialog();
                        this.$emit('merge-done');
                        // location.href="/Headhunting/MyCompany.html#/customerList";
                        location.href="/#/customerList";
                    }).catch(err => {
                        this.mergeLoading = false;
                    })
                }).catch(err => {})
            }
        },
        handleCloseDialog() {
            this.mergeCustomerId = "";
            this.dialogVisible = false;
        }
    }
}
</script>

<style lang="scss" scope>
    .customer-merge-dialog.el-dialog {
        width: 600px;
        .customer-merge-tip {
            line-height: 20px;
            color: #666;
            font-size: 14px;
            p {
                margin-bottom: 10px;
            }
        }
        .customer-merge-select {
            width: 100%;
        }
    }
</style>

<style lang="scss">
.customer-select.el-select-dropdown {
    max-width: 560px;
}
</style>
